import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  removeWhitespace(value: string) {
    if (value) 
      return value.replace(/\s/g, "")

    return '';
  }

}
