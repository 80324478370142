<div class="planning">

    <div class="planning__wrapper">

        <div class="planning__container">

            <div class="planning__content">
                <h2 class="planning__heading">
                    Land Planning
                </h2>
    
                <div class="planning__text">
                    <p>CG Homes specialise in the acquisition and promotion of strategic land through the planning process. Utilising the skills of our highly experienced in-house team of planners and architects, we navigate the often complex and fast changing planning landscape to achieve results.</p>
                    <p>CG Homes specialises in optimising the development potential of land and property and aims to work with land owners to maximise the potential of their land whilst providing high quality sustainable housing for their local communities.</p>
                    <p>Our in-depth knowledge and expertise in planning and development enables a diverse range of sites to be brought forward from conception through to delivery. We provide a bespoke and flexible service to each and every client, which can include the drafting of option or promotion agreements through to the straight purchase of land.</p>
                    <p>If you would like more information on how we can help to achieve planning permission for your land please contact us and one of our land managers will be in contact to discuss the options and how we can help.</p>
                </div>
            </div>

        </div>

    </div>

</div>