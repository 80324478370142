import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/pages/home/home.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { MenuComponent } from './components/layout/menu/menu.component';
import { MenuToggleComponent } from './components/common/menu-toggle/menu-toggle.component';
import { PrimaryButtonComponent } from './components/common/primary-button/primary-button.component';
import { ProjectComponent } from './components/pages/project/project.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { PostsComponent } from './components/common/posts/posts.component';
import { AgmCoreModule } from '@agm/core';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from "@angular/fire/auth";
import { ScrollTopComponent } from './components/common/scroll-top/scroll-top.component';
import { ScrollDownComponent } from './components/common/scroll-down/scroll-down.component';
import { AboutComponent } from './components/pages/about/about.component';
import { PostComponent } from './components/pages/post/post.component';
import { EnquiryFormComponent } from './components/forms/enquiry-form/enquiry-form.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';
import { CounterComponent } from './components/common/counter/counter.component';
import { SecondaryButtonComponent } from './components/common/secondary-button/secondary-button.component';
import { EnquirySectionComponent } from './components/pages/home/sections/enquiry-section/enquiry-section.component';
import { LandingSectionComponent } from './components/pages/home/sections/landing-section/landing-section.component';
import { FeaturedSectionComponent } from './components/pages/home/sections/featured-section/featured-section.component';
import { LocationsSectionComponent } from './components/pages/home/sections/locations-section/locations-section.component';
import { PortfolioSectionComponent } from './components/pages/home/sections/portfolio-section/portfolio-section.component';
import { NewsSectionComponent } from './components/pages/home/sections/news-section/news-section.component';
import { SuccessMessageComponent } from './components/common/success-message/success-message.component';
import { IntroSectionComponent } from './components/pages/home/sections/intro-section/intro-section.component';
import { TextLimiterPipe } from './pipes/text-limiter.pipe';
import { CarouselComponent } from './components/common/carousel/carousel.component';
import { CarouselSlideComponent } from './components/common/carousel/components/carousel-slide/carousel-slide.component';
import { IntroVideoSectionComponent } from './components/pages/home/sections/intro-video-section/intro-video-section.component';
import { BrochureFormComponent } from './components/forms/brochure-form/brochure-form.component';
import { PlanningComponent } from './components/pages/planning/planning.component';
import { ExperienceComponent } from './components/pages/experience/experience.component';
import { PageOutroComponent } from './components/common/page-outro/page-outro.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    MenuToggleComponent,
    PrimaryButtonComponent,
    ProjectComponent,
    BlogComponent,
    ContactComponent,
    PostsComponent,
    ScrollTopComponent,
    ScrollDownComponent,
    AboutComponent,
    PostComponent,
    EnquiryFormComponent,
    BrochureFormComponent,
    ProjectsComponent,
    CounterComponent,
    SecondaryButtonComponent,
    EnquirySectionComponent,
    LandingSectionComponent,
    FeaturedSectionComponent,
    LocationsSectionComponent,
    PortfolioSectionComponent,
    NewsSectionComponent,
    SuccessMessageComponent,
    IntroSectionComponent,
    TextLimiterPipe,
    CarouselComponent,
    CarouselSlideComponent,
    IntroVideoSectionComponent,
    PlanningComponent,
    ExperienceComponent,
    PageOutroComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAGqPbH4L8NgNmAVomiA8YCaowQ2uLhUng'
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
