<div class="project" *ngIf="project">

    <div class="project__wrapper">

        <div class="project__container">

            <section class="project__landing" [style.background-image]="'url(' + project.backgroundimage + ')'">
                
                <h2 class="project__landing-heading">{{project.name}}</h2>

            </section>


            <div class="project__toolbar">

                <div class="project__toolbar-container">

                    <div class="project__toolbar-button-container">
                        <button class="project__toolbar-button" routerLink="/projects">Portfolio</button>
                        <button class="project__toolbar-button" routerLink="/contact">Enquire</button>
                    </div>
                    <div class="project__toolbar-button-controls">
                        <span (click)="onPrevProject()">&#8592;</span>
                        <span (click)="onNextProject()">&#8594;</span>
                    </div>

                </div>

            </div>


            <section class="project__brochure" *ngIf="project.showBrochure">

                <h2 class="project__brochure-heading">Download a brochure</h2>
                <app-brochure-form class="project__brochure-form" [project]="project"></app-brochure-form>
                <p class="project__brochure-text">
                    <small>
                        Your information will be used to respond to your query and send you information about this development. We will only send you relevant information. We will never sell your information to third parties.
                    </small>
                </p>

            </section>


            <section class="project__details">

                <div class="project__details-container">
                    <div class="project__detail">
                        <i class="project__detail-icon fas fa-pencil-ruler"></i>
                        <span class="project__detail-heading">Architect</span>
                        <span>{{project.architect}}</span>
                    </div>
                    <div class="project__detail">
                        <i class="project__detail-icon fas fa-th-large"></i>
                        <span class="project__detail-heading">Units</span>
                        <span>{{project.units}}</span>
                    </div>
                    <div class="project__detail">
                        <i class="project__detail-icon fas fa-map-marker-alt"></i>
                        <span class="project__detail-heading">Location</span>
                        <span>{{project.location}}</span>
                    </div>
                </div>

            </section>


            <section class="project__info">

                <div class="project__info-container">
                    <h2 class="project__info-heading">{{project.name}}</h2>
                    <p class="project__info-summary">{{project.summary}}</p>
                    <div class="project__info-html" [innerHTML]="project.html"></div>
                </div>

            </section>


            <section class="project__images" *ngIf="project.showImages">

                <div class="project__images-container">
                    <app-carousel [data]="project.images">
                        <app-carousel-slide *ngFor="let image of project.images" [style.background-image]="'url(' + image + ')'"></app-carousel-slide>
                    </app-carousel>
                </div>
                
            </section>


            <section class="project__video" *ngIf="project.showVideo && videoUrl">

                <div class="project__video-container">
                    <iframe [src]="videoUrl" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
  
            </section>

            
            <section class="project__location">

                <agm-map class="project__location-map"
                         [disableDefaultUI]="false"
                         [zoomControl]="true"
                         [zoom]="8"
                         [scrollwheel]="false"
                         [latitude]="latitude" 
                         [longitude]="longitude"
                         [styles]="styles">
           
                    <agm-marker [latitude]="project.latitude"
                                [longitude]="project.longitude"
                                [iconUrl]="'/assets/marker.png'">    
                    </agm-marker>
                </agm-map>

            </section>

        </div>

    </div>

</div>