import { Component } from '@angular/core';
import { ControlsService } from 'src/app/services/controls.service';

@Component({
  selector: 'app-scroll-top',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.scss']
})
export class ScrollTopComponent {

  constructor(private controlsService: ControlsService) {}

  onScrollToTop() {
    this.controlsService.scrollToTop();
    this.controlsService.updateMenuState(false);
  }

}
