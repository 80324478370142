<section class="landing-section">

    <span class="landing-section__background"></span>

    <div class="landing-section__wrapper">

        <div class="landing-section__container">

            <div class="landing-section__content">
                <img class="landing-section__logo" src="assets/logo-v2.png" alt="CG Homes" />
                <app-primary-button class="landing-section__button" (click)="onScrollToView('.featured-section')" [style.opacity]="opacity">View projects</app-primary-button>
            </div>

            <div class="landing-section__scroll-down-wrapper">
                <div class="landing-section__scroll-down-container"
                    [style.transform]="'translateY(' + transform + 'px)'">
                    <div [style.opacity]="opacity">
                        <app-scroll-down (click)="onScrollToView('.featured-section')"></app-scroll-down>
                    </div>
                </div>
            </div>

        </div>

    </div>

</section>