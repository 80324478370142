import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ControlsService } from 'src/app/services/controls.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  destroy$ = new Subject<boolean>();

  show = true;
  active = false;
  scroll = false;
  scrollShow = false;
  lastScrollTop = 0;

  showScrollToTop = false;

  constructor(
    private controlsService: ControlsService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getMenuState();
    this.handleHomeConfig();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  @HostListener('window:scroll')
  onScroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (scrollTop > 100) {
      this.scrollShow = true;
      this.showScrollToTop = true;
    }
    else {
      this.scrollShow = false;
      this.showScrollToTop = false;
    }

    this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
  }

  private getMenuState() {
    this.controlsService.$currentMenuState
      .pipe(takeUntil(this.destroy$))
      .subscribe(state => this.active = state);
  }

  private handleHomeConfig() {
    this.router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        if (event instanceof NavigationEnd && this.router.url === '/home') {
          this.scroll = true;
          this.show = false;
        } else if (event instanceof NavigationEnd && this.router.url !== '/home') {
          this.scroll = false;
          this.show = true;
        }
      });
  }

}
