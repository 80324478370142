import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Constants } from 'src/app/app.constants';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  animations: [
    trigger('fade', [
      transition(':leave', [
        animate(500, style({opacity: 0}))
      ])
    ])
  ]
})
export class ProjectsComponent implements OnInit {

  projects: any[] = [];
  filteredProjects: any[] = [];
  developments = 0;
  value = 0;
  units = 0;

  constructor(
    private firestore: AngularFirestore,
    public utilitiesService: UtilitiesService
  ) {
    firestore
      .collection('projects')
      .valueChanges({ idField: 'id' })
      .subscribe(response => {
        const projects = response.filter(p => !Constants.excludedProjectIds.includes(p.id));
        this.projects = this.filteredProjects = projects.sort((a: any, b: any) => a.label.localeCompare(b.label));
        this.developments = this.projects.length;
        this.value = this.getTotalValue(this.projects);
        this.units = this.getTotalUnits(this.projects);
      });
  }

  ngOnInit() {
  }

  onFilter(value: string) {
    if (value === 'All') {
      this.filteredProjects = this.projects;
    } else {
      this.filteredProjects = this.projects.filter(p => p.status === value);
    }  
  }

  private getTotalValue(projects: any[]) {
    let total = 0;

    projects.forEach(p => {
      total = total + p.value;
    });

    return this.formatTotal(total);
  }

  private getTotalUnits(projects: any[]) {
    let total = 0;

    projects.forEach(p => {
      total = total + p.units;
    });

    return total;
  }

  private formatTotal(value: number) {
      // Nine Zeroes for Billions
      return Math.abs(Number(value)) >= 1.0e+9
  
      ? Math.abs(Number(value)) / 1.0e+9

      // Six Zeroes for Millions 
      : Math.abs(Number(value)) >= 1.0e+6
  
      ? Math.abs(Number(value)) / 1.0e+6

      // Three Zeroes for Thousands
      : Math.abs(Number(value)) >= 1.0e+3
  
      ? Math.abs(Number(value)) / 1.0e+3
  
      : Math.abs(Number(value));
  }

}
