<div class="projects">

    <div class="projects__wrapper">

        <div class="projects__container">

            <div class="projects__stats">

                <div class="projects__stats-item">
                    <app-counter class="projects__stats-value" [end]="developments"></app-counter>
                    <span class="projects__stats-heading">Developments</span>
                </div>
                <div class="projects__stats-item">
                    <app-counter class="projects__stats-value" [end]="value" [increment]="0.01"></app-counter>
                    <span class="projects__stats-heading">Value</span>
                </div>
                <div class="projects__stats-item">
                    <app-counter class="projects__stats-value" [end]="units"></app-counter>
                    <span class="projects__stats-heading">Units</span>
                </div>

            </div>

            <div class="projects__filters">

                <div class="projects__filters-container">
                    <button class="projects__filters-button" (click)="onFilter('All')">All</button>
                    <button class="projects__filters-button" (click)="onFilter('On Sale')">On Sale</button>
                    <button class="projects__filters-button" (click)="onFilter('Sold Out')">Sold Out</button>
                    <button class="projects__filters-button" (click)="onFilter('Completed')">Completed</button>
                </div>

            </div>

            <div class="projects__grid">

                <div class="projects__grid-container">

                    <div class="projects__grid-item" *ngFor="let project of filteredProjects" @fade>

                        <div class="projects__item-container"
                            [routerLink]="['/projects/' + project.id + '/' + utilitiesService.removeWhitespace(project.name)]">

                            <span class="projects__item-background"
                                [style.background-image]="'url(' + project.backgroundimage + ')'"></span>

                            <div class="projects__item-content">
                                <span class="projects__item-stage">{{project.stage}}</span>

                                <h3 class="projects__item-heading">{{project.heading}}</h3>

                                <div class="projects__item-details">
                                    <div class="projects__detail">
                                        <span class="projects__detail-heading">Status</span>
                                        <span>{{project.status}}</span>
                                    </div>
                                    <div class="projects__detail">
                                        <span class="projects__detail-heading">Units</span>
                                        <span>{{project.units}}</span>
                                    </div>
                                    <div class="projects__detail">
                                        <span class="projects__detail-heading">Location</span>
                                        <span>{{project.location}}</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

</div>