import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-portfolio-section',
  templateUrl: './portfolio-section.component.html',
  styleUrls: ['./portfolio-section.component.scss']
})
export class PortfolioSectionComponent {

  projects!: any[];

  excluded = [
    'JSwfB352vqfPcWTBrZSC',
    'y56IcxotS577bCcLkctm',
    '6twz0WFz7SsqYy34UGbQ',
    '5KV8tcNSYtXwSXmF6Af0',
    'kEldA6QN7567PhGiiK2d'
  ];

  constructor(
    private firestore: AngularFirestore,
    public utilitiesService: UtilitiesService
  ) {
    firestore
      .collection('projects')
      .valueChanges({ idField: 'id' })
      .subscribe(response => {
        const projects = response.filter(p => !this.excluded.includes(p.id));
        this.projects = projects.sort((a: any, b: any) => a.label.localeCompare(b.label));
      });
  }

}
