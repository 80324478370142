<div class="enquiry-section">

    <div class="enquiry-section__wrapper">

        <div class="enquiry-section__container">

            <section class="enquiry-section__form-container">
                <h2 class="enquiry-section__heading">Make an enquiry</h2>
                <app-enquiry-form class="enquiry-section__form" *ngIf="!sent" (sent)="sent = $event"></app-enquiry-form>
                <app-success-message *ngIf="sent" [message]="contact.text"></app-success-message>
            </section>

        </div>

    </div>

</div>