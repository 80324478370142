<header class="header">

    <div class="header__wrapper">

        <div class="header__container">

            <app-menu-toggle></app-menu-toggle>

            <img class="header__logo" 
                 [class.hide]="active" 
                 [class.show]="show"
                 [class.scroll]="scroll" 
                 [class.scroll-show]="scrollShow" 
                 src="assets/logo-v2.png"
                 alt="CG Homes"
                 routerLink="/" />

            <app-scroll-top class="header__scroll-top" [class.show]="showScrollToTop"></app-scroll-top>

        </div>

    </div>

</header>