<footer class="footer" [ngClass]="theme">
    
    <div class="footer__wrapper">

        <div class="footer__container">

            <div class="footer__logo-container" routerLink="/">
                <img src="assets/logo-v2.png" alt="CG Homes" *ngIf="theme === 'dark'" class="footer__logo dark" />
                <img src="assets/logo-v2.png" alt="CG Homes" *ngIf="theme === 'light'" class="footer__logo light" />
            </div>

            <div class="footer__links">
                <ul class="footer__list">
                    <li class="footer__list-item" routerLink="/home" routerLinkActive="active">Home</li>
                    <li class="footer__list-item" routerLink="/projects" routerLinkActive="active">Projects</li>
                    <li class="footer__list-item" routerLink="/blog" routerLinkActive="active">News</li>
                    <li class="footer__list-item" routerLink="/contact" routerLinkActive="active">Contact</li>
                </ul>
            </div>

        </div>

    </div>

</footer>