import { Component, OnChanges, Input, SimpleChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss']
})
export class CounterComponent implements OnChanges, OnInit {

  @Input() start = 0;
  @Input() end = 0;
  @Input() duration = 3000;
  @Input() increment = 1;

  current = 0;

  ngOnInit() {
    this.current = this.start;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.end.currentValue > 0) {
      this.intializeCount();
    }
  }

  private intializeCount() {
    if (this.end && this.end > 0) {
      const increment = this.end > this.start? this.increment : -this.increment;
      const stepTime = this.increment == 1? 10 : 1;
      this.incrementValue(increment, stepTime);
    }
  }

  private incrementValue(increment: number, stepTime: number) {
    const timer = setInterval(() => {
      this.current += increment;

      if (this.current >= this.end) {
        clearInterval(timer);
      }
    }, stepTime);
  }

}
