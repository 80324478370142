<div class="page-outro">

    <div class="page-outro__wrapper">

        <div class="page-outro__container">

            <div class="page-outro__content">
                <h2 class="page-outro__heading">
                    If your land has potential for planning permission please contact us.
                </h2>

                <app-primary-button class="page-outro__button" routerLink="/contact" theme="dark" [overlay]="true">
                    Get in touch
                </app-primary-button>
            </div>

        </div>

    </div>

</div>