import { Component, Input, Output, SimpleChanges, EventEmitter, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss']
})
export class PostsComponent implements OnInit {

  @Input() max = 1000000;

  @Output() complete = new EventEmitter();

  posts: any[] = [];
  filteredPosts: any[] = [];

  excluded = [
    'fakpA6e99YL0JE7RgSs9'
  ];

  constructor(
    private firestore: AngularFirestore,
    public utilitiesService: UtilitiesService
  ) {
    firestore
      .collection('posts')
      .valueChanges({ idField: 'id' })
      .subscribe((response: any[]) => {
        console.log(response);
        const posts = response.filter(p => !this.excluded.includes(p.id));
        this.posts = posts.sort((a, b) => a.date - b.date);

        this.filteredPosts = posts
          .sort((a, b) => a.date - b.date)
          .slice(0, this.max);
      });
  }

  ngOnInit() {
    if (this.max >= this.posts.length)
      this.complete.emit();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.max = changes.max.currentValue;

    this.filteredPosts = this.posts
      .sort((a, b) => a.date - b.date)
      .slice(0, this.max);

    if (!changes.max.firstChange && this.max >= this.posts.length)
      this.complete.emit();
  }
}
