<section class="featured-section" *ngIf="featured">

    <div class="featured-section__wrapper">

        <div class="featured-section__container" data-aos="fade-up" data-aos-offset="0" data-aos-delay="0"
            data-aos-duration="500">

            <div class="featured-section__content">
                <div class="featured-section__text-container">

                    <h3 class="featured-section__primary-heading">
                        Featured project
                    </h3>

                    <h4 class="featured-section__secondary-heading">
                        {{featured.heading}}
                    </h4>

                    <p class="featured-section__text">
                        {{featured.summary | textLimiter: 200}}
                    </p>

                    <app-primary-button class="featured-section__button" 
                                        theme="dark" 
                                        [overlay]="true"
                                        [routerLink]="['/projects/' + featured.id + '/' + utilitiesService.removeWhitespace(featured.name).toLowerCase()]">
                        Find out more
                    </app-primary-button>
                </div>
                
                <div class="featured-section__image-container">
                    <div class="featured-section__image"
                         [style.background-image]="'url(' + featured.backgroundimage + ')'">
                    </div>
                </div>
            </div>

        </div>

    </div>

</section>