<section class="intro-section" *ngIf="home">

    <div class="intro-section__wrapper">

        <div class="intro-section__container">

            <div class="intro-section__content">

                <h2 class="intro-section__heading">{{home.heading}}</h2>

                <div class="intro-section__html">
                    <p>We are an experienced developer and strategic land promoter covering all areas of the UK.</p>
                </div>

            </div>

        </div>

    </div>

</section>