<section class="portfolio-section">

    <div class="portfolio-section__wrapper">

        <div class="portfolio-section__container" *ngIf="projects">

            <app-carousel class="portfolio-section__carousel" [data]="projects">
                <app-carousel-slide *ngFor="let project of projects; let i = index;"
                    [style.background-image]="'url(' + project.backgroundimage + ')'">
                    <div class="portfolio-section__project-info-wrapper">

                        <div class="portfolio-section__project-info-container">
                            <h4 class="portfolio-section__primary-heading">
                                {{project.name}}
                            </h4>

                            <h5 class="portfolio-section__secondary-heading">
                                {{project.heading}}
                            </h5>

                            <p class="portfolio-section__text">
                                {{project.summary | textLimiter: 200}}
                            </p>

                            <app-primary-button class="portfolio-section__button" theme="dark" [overlay]="true"
                                [routerLink]="['/projects/' + project.id + '/' + utilitiesService.removeWhitespace(project.name)]">
                                View project
                            </app-primary-button>
                        </div>

                    </div>
                </app-carousel-slide>
            </app-carousel>

        </div>

    </div>

</section>


<!-- <div class="portfolio-section__slider-wrapper">

                <div class="portfolio-section__slider-container">

                    <div class="portfolio-section__project-wrapper" *ngFor="let project of projects$ | async">

                        <div class="portfolio-section__project-container">

                            <div class="portfolio-section__project-image" [style.background-image]="'url(' + project.backgroundimage + ')'"></div>

                            <div class="portfolio-section__project-info">

                                <h4 class="portfolio-section__primary-heading">
                                    {{project.name}}
                                </h4>

                                <h5 class="portfolio-section__secondary-heading">
                                    {{project.heading}}
                                </h5>

                                <p class="portfolio-section__text">
                                    {{project.summary | textLimiter: 200}}
                                </p>

                                <app-primary-button class="portfolio-section__button" theme="dark" [overlay]="true"
                                    [routerLink]="['/projects/' + project.id + '/' + utilitiesService.removeWhitespace(project.name)]">
                                    View project &#8594;
                                </app-primary-button>

                            </div>

                        </div>

                    </div>
                
                </div>
                
            </div> -->