<div class="experience">

    <div class="experience__wrapper">

        <div class="experience__container">

            <div class="experience__content">
                <h2 class="experience__heading">
                    Experience
                </h2>
    
                <div class="experience__text">
                    <p>CG homes have over three decades of experience of successfully promoting land through the complexities of the UK planning system.</p>
                    <p>We have developed and promoted a variety of residential schemes across the UK ranging from bespoke luxury residential schemes to large scale strategic sites of over 100 dwellings.</p>
                    <p>CG Homes also have delivered commercial or mixed use schemes by utilising the diverse experience of our planing and architectural teams.</p>
                    <p>As we have grown our business over the years CG Homes has evolved into a national strategic land promoter. Our expertise and experience in navigating the complex planning process has enabled us to sign long term option and promotion agreements with land owners to deliver large significant sites.</p>
                    <p>Local plan promotion has become an important part of our long term strategy and we favour sites which local councils have identified as holding strategic value. Some of our larger previous successful sites were on previous local plans and we have become experts in achieving planning for such sites whilst helping our land owner partners achieve maximum value.</p>
                </div>
            </div>

        </div>

    </div>

</div>