<div class="contact">

    <div class="contact__wrapper">

        <div class="contact__container">

            <section class="contact__form-container">
                <h2 class="contact__heading">Get in touch today</h2>
                <app-enquiry-form *ngIf="!sent" class="contact__form" (sent)="sent = $event"></app-enquiry-form>
                <app-success-message *ngIf="sent" [message]="contact.text"></app-success-message>
            </section>

            <section class="contact__location">
                <div class="contact__location-container">
                    <i class="contact__location-icon fas fa-map-marker-alt"></i>
                    <address class="contact__location-address">
                        {{contact.street}}<br>
                        {{contact.town}},
                        {{contact.postcode}}
                    </address>
                    <a class="contact__location-phone" href="tel:{{contact.phone}}">
                        <span>{{contact.phone}}</span>
                    </a>
                </div>
            </section>

        </div>

    </div>

</div>