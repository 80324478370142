import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './components/pages/about/about.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ExperienceComponent } from './components/pages/experience/experience.component';
import { HomeComponent } from './components/pages/home/home.component';
import { PlanningComponent } from './components/pages/planning/planning.component';
import { PostComponent } from './components/pages/post/post.component';
import { ProjectComponent } from './components/pages/project/project.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home', 
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'projects',
    component: ProjectsComponent
  },
  {
    path: 'projects/:id/:name',
    component: ProjectComponent
  },
  {
    path: 'experience',
    component: ExperienceComponent
  },
  {
    path: 'planning',
    component: PlanningComponent
  },
  {
    path: 'blog',
    component: BlogComponent
  },
  {
    path: 'blog/:id/:name',
    component: PostComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  { 
    path: '**', 
    component: HomeComponent 
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
