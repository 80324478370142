import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ControlsService } from 'src/app/services/controls.service';

@Component({
  selector: 'app-menu-toggle',
  templateUrl: './menu-toggle.component.html',
  styleUrls: ['./menu-toggle.component.scss']
})
export class MenuToggleComponent implements OnInit {

  destroy$ = new Subject<boolean>();

  active = false;

  constructor(private controlsService: ControlsService) {}

  ngOnInit() {
    this.controlsService.$currentMenuState
      .pipe(takeUntil(this.destroy$))
      .subscribe(state => this.active = state);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onToggle() {
    this.active = !this.active; 
    this.controlsService.updateMenuState(this.active);
  }

}
