import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  contact: any;
  sent = false;

  constructor(private firestore: AngularFirestore) {
    this.contact = firestore
      .collection('pages')
      .doc('contact')
      .valueChanges()
      .subscribe((response: any) => {
        this.contact = response;
      });
  }

}
