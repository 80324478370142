import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-enquiry-form',
  templateUrl: './enquiry-form.component.html',
  styleUrls: ['./enquiry-form.component.scss']
})
export class EnquiryFormComponent implements OnInit {

  @Output() sent = new EventEmitter<boolean>();

  form!: FormGroup;
  isPending = false;
  hasSubmitted = false;

  constructor(
    private firestore: AngularFirestore,
    private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.buildForm();
  }

  onSubmit() {
    this.hasSubmitted = true;

    if(this.form.invalid)
      return;
    
    this.isPending = true;

    this.firestore
    .collection("emails")
    .add({
      to: environment.email,
      message: {
        subject: "Landmark Website Enquiry",
        text: `
          Name: ${this.form.controls.name.value}, 
          Phone: ${this.form.controls.phone.value}, 
          Email: ${this.form.controls.email.value},
          Subject: ${this.form.controls.subject.value},
          Message: ${this.form.controls.message.value}
        `,
        html: `
          <h2>Landmark Website Enquiry</h2>
          <p>Name: ${this.form.controls.name.value}</p>
          <p>Phone: ${this.form.controls.phone.value}</p>
          <p>Email: ${this.form.controls.email.value}</p>
          <p>Subject: ${this.form.controls.subject.value}</p>
          <p>Message: ${this.form.controls.message.value}</p>
          <p><small>Email sent on ${new Date()}</small></p>
        `,
      },
    })
    .then(() => {
      setTimeout(() => {
        this.hasSubmitted = false;
        this.isPending = false;
        this.sent.emit(true);
      }, 1000);
    });
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      subject: [''],
      message: ['']
    });
  }

}
