import { Component } from '@angular/core';

@Component({
  selector: 'app-news-section',
  templateUrl: './news-section.component.html',
  styleUrls: ['./news-section.component.scss']
})
export class NewsSectionComponent {

  postLimit = 2;
  showPostsButton = true;

  onLoadMorePosts() {
    this.postLimit = this.postLimit * 2;
  }

}
