<div class="about">

    <div class="about__wrapper">

        <div class="about__container">

            <div class="about__content">
                <h2 class="about__heading">
                    Who we are
                </h2>
    
                <div class="about__text">
                    <p>Our management team seek development opportunities in the form of off market sites, marketed sites and property in need of renovation. Once planning permission is attained it will subsequently sold or developed. Our aim is to add value to each site and extract maximum the profit.</p>
                    <p>Our multi-disciplined approach means that we can employ a number of strategies to achieve our goals. The management team believes that since the credit crisis there are many opportunities to be exploited in the south east of England. Our development partner portfolio consists of properties with strong value add potential in the form of new builds and renovation with a bias on residential.</p>
                </div>
    
    
                <h2 class="about__heading">
                    What we do
                </h2>
    
                <div class="about__text">
                    <p>From commercial to residential property our development team, CG Homes aim to take advantage of the considerable demand for affordable and high quality housing and office/industrial space in the South East. A highly experienced management team has been assembled to administer and oversee the developments. By combining their wealth of experience they take a cautious approach to each project and mitigate risk to ensure a smooth development process from start to finish.</p>
                </div>
            </div>

        </div>

    </div>

</div>