import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textLimiter'
})
export class TextLimiterPipe implements PipeTransform {

  transform(value: string, maxCharacters: number): unknown {
    if (value.length > maxCharacters) {
      let x = value.substring(0, maxCharacters - 3);
      let y = x.concat('...');
      return y;
    }

    return value;
  }

}
