<div class="post" *ngIf="post">

    <div class="post__wrapper">

        <div class="post__container">

            <section class="post__landing">

                <div class="post__landing-container">

                    <div class="post__landing-button-container">
                        <button class="post__landing-button" routerLink="/blog">
                            <span>&#8592;&nbsp;&nbsp;</span>
                            <span>View all posts</span>
                        </button>
                    </div>
                    <div class="post__landing-header">
                        <h2 class="post__landing-heading" data-aos="fade-left" data-aos-delay="700"
                            data-aos-duration="1000">
                            {{post.heading}}
                        </h2>

                        <span class="post__landing-date" *ngIf="post.date" data-aos="fade-right" data-aos-delay="900"
                            data-aos-duration="1000">
                            Posted on {{post.date.seconds * 1000 | date: 'd MMM yyyy'}}
                        </span>
                    </div>
                    <div class="post__landing-image" [style.background-image]="'url(' + post.backgroundimage + ')'"
                        data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000"></div>

                </div>

            </section>

            <section class="post__info">

                <div class="post__info-container">

                    <div class="post__info-header">
                        <h2 class="post__info-heading">{{post.heading}}</h2>
                        <p class="post__info-summary">{{post.summary}}</p>
                    </div>

                    <div class="post__info-html" [innerHTML]="post.html"></div>

                </div>

            </section>

            <section class="post__controls">

                <div class="post__prev" *ngIf="prev" (click)="onPrevPost()">
                    <span class="post__control-background"
                        [style.background-image]="'url(' + prev.backgroundimage + ')'"></span>
                    <div class="post__prev-content">
                        <span>&#8592;</span>
                        <h5>{{prev.heading}}</h5>
                    </div>
                </div>
                <div class="post__next" *ngIf="next" (click)="onNextPost()">
                    <span class="post__control-background"
                        [style.background-image]="'url(' + next.backgroundimage + ')'"></span>
                    <div class="post__next-content">
                        <h5>{{next.heading}}</h5>
                        <span>&#8594;</span>
                    </div>
                </div>

            </section>

        </div>

    </div>

</div>