import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ControlsService {

  private menuState = new BehaviorSubject<boolean>(false);
  $currentMenuState = this.menuState.asObservable();

  constructor() { }

  updateMenuState(state: boolean) {
    this.menuState.next(state);
  }

  scrollToView(className: string) {
    const element = document.querySelector(className);

    if (element) {
      element.scrollIntoView({behavior: 'smooth'});
    }
  }

  scrollToTop() {
    const element = document.querySelector('body');

    if (element) {
      element.scrollIntoView({behavior: 'smooth'});
    }
  }
  
}
