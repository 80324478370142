<div class="locations-section">

    <div class="locations-section__wrapper">

        <div class="locations-section__container">

            <div class="locations-section__list-container" [class.active]="showLocations">
                <i class="locations-section__icon fas fa-map-marker-alt"></i> 
                <ul class="locations-section__list">
                    <li class="locations-section__list-item" *ngFor="let project of projects">
                        <b>{{project.label}}.</b> {{project.address}}
                    </li>
                </ul>
            </div>

            <div class="locations-section__map-container">
                <agm-map class="locations-section__map"
                         [disableDefaultUI]="false"
                         [zoomControl]="true"
                         [zoom]="8"
                         [scrollwheel]="false"
                         [latitude]="latitude" 
                         [longitude]="longitude"
                         [styles]="styles">
           
                    <agm-marker *ngFor="let project of projects"
                                [latitude]="project.latitude"
                                [longitude]="project.longitude"
                                [iconUrl]="'/assets/marker.png'"
                                [label]="project.label">    
                    </agm-marker>
   
                </agm-map>
            </div>

        </div>

    </div>

</div>