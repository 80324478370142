<div class="home">

    <div class="home__wrapper">

        <div class="home__container">

            <app-landing-section></app-landing-section>

            <app-intro-section></app-intro-section>

            <app-featured-section></app-featured-section>

            <app-portfolio-section></app-portfolio-section>

            <!-- <app-intro-video-section></app-intro-video-section> -->

            <app-locations-section></app-locations-section>

            <app-enquiry-section></app-enquiry-section>

        </div>

    </div>

</div>