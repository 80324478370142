import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit, AfterViewInit {

  @ViewChild('container') container!: ElementRef;

  @Input() data: any[] = [];
  @Input() height = 80;
  @Input() showControls = true;
  @Input() showPoints = true;

  slideWidth = 0;
  currentIndex = 0;

  translate = 0;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.slideWidth = this.container.nativeElement.offsetWidth;
  }

  onMove(direction: string) {
    if (direction == 'prev') this.currentIndex = this.currentIndex - 1;
    if (direction == 'next') this.currentIndex = this.currentIndex + 1;
    const movePoint = this.slideWidth * this.currentIndex;
    this.translate = +`-${movePoint}`;
  }

  onSelect(index: number) {
    this.currentIndex = index;
    const movePoint = this.slideWidth * index;
    this.translate = +`-${movePoint}`;
  }

}
