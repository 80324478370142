import { Component, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-brochure-form',
  templateUrl: './brochure-form.component.html',
  styleUrls: ['./brochure-form.component.scss']
})
export class BrochureFormComponent {

  @Input() project: any;

  form!: FormGroup;
  isPending = false;
  hasSubmitted = false;

  constructor(
    private firestore: AngularFirestore,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.buildForm();
    this.populateForm();
  }

  onSubmit() {
    this.hasSubmitted = true;

    if (this.form.invalid)
      return;

    this.isPending = true;

    this.firestore
      .collection("emails")
      .add({
        to: environment.email,
        message: {
          subject: "Landmark Website Brochure Request",
          text: `
          Project Name: ${this.form.controls.project.value}, 
          Brochure: ${this.form.controls.brochure.value}, 
          Email: ${this.form.controls.email.value}
        `,
          html: `
          <h2>Landmark Website Brochure Request</h2>
          <p>Project Name: ${this.form.controls.project.value}</p>
          <p>Brochure: ${this.form.controls.brochure.value}</p>
          <p>Email: ${this.form.controls.email.value}</p>
          <p><small>Email sent on ${new Date()}</small></p>
        `,
        },
      })
      .then(() => {
        setTimeout(() => {
          this.hasSubmitted = false;
          this.isPending = false;
        }, 1000);
      });
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      project: [''],
      brochure: [''],
      email: ['', [Validators.required, Validators.email]]
    });
  }

  private populateForm() {
    this.form.controls.project.setValue(this.project.name);
    this.form.controls.brochure.setValue(this.project.brochure);
  }

}
