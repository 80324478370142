<form class="enquiry-form" [formGroup]="form">

    <div class="enquiry-form__row">

        <div class="enquiry-form__control">
            <div class="enquiry-form__field">
                <input type="text" placeholder="Full name*" formControlName="name">
            </div>
            <div class="enquiry-form__error-message"
                *ngIf="hasSubmitted && form.controls.name.invalid">
                Please enter a name
            </div>
        </div>

        <div class="enquiry-form__control">
            <div class="enquiry-form__field">
                <input type="tel" placeholder="Phone" formControlName="phone">
            </div>
            <div class="enquiry-form__error-message"
                *ngIf="hasSubmitted && form.controls.phone.invalid">
                Please enter a valid phone number
            </div>
        </div>

    </div>


    <div class="enquiry-form__row">

        <div class="enquiry-form__control">
            <div class="enquiry-form__field">
                <input type="email" placeholder="Email*" formControlName="email">
            </div>
            <div class="enquiry-form__error-message"
                *ngIf="hasSubmitted && form.controls.email.invalid">
                Please enter a valid email address
            </div>
        </div>

        <div class="enquiry-form__control">
            <div class="enquiry-form__field">
                <input type="text" placeholder="Subject" formControlName="subject">
            </div>
            <div class="enquiry-form__error-message"
                *ngIf="hasSubmitted && form.controls.subject.invalid">
                Please enter a subject
            </div>
        </div>

    </div>


    <div class="enquiry-form__row">

        <div class="enquiry-form__control">
            <div class="enquiry-form__field">
                <textarea placeholder="Message" formControlName="message"></textarea>
            </div>
            <div class="enquiry-form__error-message"
                *ngIf="hasSubmitted && form.controls.message.invalid">
                Please enter a message
            </div>
        </div>

    </div>

    <app-primary-button class="enquiry-form__button" *ngIf="!isPending" theme="dark" [overlay]="true" (click)="onSubmit()">
        Send
    </app-primary-button>

    <app-primary-button class="enquiry-form__button" *ngIf="isPending" theme="dark" [overlay]="true" (click)="onSubmit()">
        Sending...
    </app-primary-button>

</form>