import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-enquiry-section',
  templateUrl: './enquiry-section.component.html',
  styleUrls: ['./enquiry-section.component.scss']
})
export class EnquirySectionComponent {

  contact: any;
  sent = false;

  constructor(private firestore: AngularFirestore) {
    this.contact = firestore
      .collection('pages')
      .doc('contact')
      .valueChanges()
      .subscribe((response: any) => {
        this.contact = response;
      });
  }
}
