<div class="menu" 
     [class.active]="active"
     [class.close]="!active" 
     [class.show]="show"
     [class.scroll]="scroll" 
     [class.scroll-show]="scrollShow" >

    <div class="menu__wrapper">

        <div class="menu__container">

            <img class="menu__logo" src="assets/logo-v2.png" alt="CG Homes" />

            <ul class="menu__list">
                <li class="menu__list-item" routerLink="/home" (click)="onCloseMenu()">Home</li>
                <li class="menu__list-item" routerLink="/about" (click)="onCloseMenu()">About</li>
                <li class="menu__list-item" routerLink="/projects" (click)="onCloseMenu()">Projects</li>
                <li class="menu__list-item" routerLink="/experience" (click)="onCloseMenu()">Experience</li>
                <li class="menu__list-item" routerLink="/planning" (click)="onCloseMenu()">Land Planning</li>
                <li class="menu__list-item" routerLink="/blog" (click)="onCloseMenu()">News</li>
                <li class="menu__list-item" routerLink="/contact" (click)="onCloseMenu()">Contact</li>
            </ul>

        </div>

    </div>

</div>
