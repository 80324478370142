<section class="news-section">

    <div class="news-section__wrapper">

        <div class="news-section__container">

            <app-posts class="news-section__posts"
                       [max]="postLimit" 
                       (complete)="showPostsButton = false">
            </app-posts>

            <button class="news-section__button"
                    *ngIf="showPostsButton"
                    (click)="onLoadMorePosts()">
                    Load more...
            </button>

        </div>

    </div>

</section>