import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Post } from 'src/app/interfaces/post.interface';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent {

  posts: any[] = [];
  post: any;
  next: any;
  prev: any;

  constructor(
    private firestore: AngularFirestore,
    private utilitiesService: UtilitiesService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.loadProject();
    
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.loadProject();
      }
    });
  }

  onPrevPost() {
    const prev = this.getPrevPost();
    if (prev) this.reloadRoute(prev);
  }

  onNextPost() {
    const next = this.getNextPost();
    if (next) this.reloadRoute(next);
  }

  private getPrevPost() {
    const index = this.posts.indexOf(this.post);
    let post = null;

    if (index + 1 == this.posts.length) {
      post = this.posts[0];
    } else {
      post = this.posts[index + 1];
    }

    this.prev = post;
    return post;
  }

  private getNextPost() {
    const index = this.posts.indexOf(this.post);
    let post = null;

    if (index == 0) {
      post = this.posts[this.posts.length - 1];
    } else {
      post = this.posts[index - 1];
    }

    this.next = post;
    return post;
  }

  private loadProject() {
    const id = this.route.snapshot.params.id;

    if (id) {
      this.post = this.firestore
        .collection('posts')
        .doc(id)
        .get();

      this.firestore
        .collection('posts')
        .valueChanges({ idField: 'id' })
        .subscribe((response: any[]) => {
          this.posts = response;
          this.post = response.find(p => p.id === id);
          this.getPrevPost();
          this.getNextPost();
        });
    } else {
      this.router.navigate(['/blog']);
    }
  }

  private reloadRoute(post: Post) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/blog/' + post.id + '/' + this.utilitiesService.removeWhitespace(post.heading).toLowerCase()])
    );
  }


}
