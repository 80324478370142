import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-featured-section',
  templateUrl: './featured-section.component.html',
  styleUrls: ['./featured-section.component.scss']
})
export class FeaturedSectionComponent {

  featured: any;

  constructor(private firestore: AngularFirestore,
              public utilitiesService: UtilitiesService) {

    firestore
      .collection('projects')
      .valueChanges({ idField: 'id' })
      .subscribe((projects: any[]) => {
        this.featured = projects.find(p => p.isfeatured === 'true');
      });
  }

}
