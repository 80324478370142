import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-intro-section',
  templateUrl: './intro-section.component.html',
  styleUrls: ['./intro-section.component.scss']
})
export class IntroSectionComponent {

  home: any;

  constructor(
    private firestore: AngularFirestore
  ) {
    firestore
      .collection('pages')
      .doc('home')
      .valueChanges()
      .subscribe((response: any) => {
        this.home = response;
      });
  }

}
