<div class="posts" *ngIf="posts">

    <div class="posts__wrapper">

        <div class="posts__container">
            
            <div class="posts__post" 
                 *ngFor="let post of filteredPosts">

                <article class="posts__article">
                
                    <div class="posts__image"
                         [style.background-image]="'url(' + post.backgroundimage + ')'"
                         [routerLink]="['/blog/' + post.id + '/' + utilitiesService.removeWhitespace(post.heading)]">
                    </div>
    
                    <header class="posts__header">
                        <span class="posts__date">{{post.date.seconds * 1000 | date: 'd MMM yyyy'}}</span>
                        <h3 class="posts__heading">{{post.heading}}</h3>
                    </header>
    
                    <main class="posts__content">
                        <p>
                            {{post.summary | textLimiter: 120}}
                        </p>
                    </main>
    
                    <footer class="posts__footer">
                        <app-primary-button class="posts__button" theme="dark" [overlay]="true" [routerLink]="['/blog/' + post.id + '/' + utilitiesService.removeWhitespace(post.heading)]">
                            Read more
                        </app-primary-button>
                    </footer>
    
                </article>

            </div>

        </div>
        
    </div>

</div>