import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  theme = 'light';

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.handleTheme();
      }
    });
  }

  private handleTheme() {
    const dark = 'dark';
    const light = 'light';

    switch (true) {
      case this.router.url.includes('/login'):
        this.theme = dark;
        break;
      case this.router.url === '/about':
        this.theme = dark;
        break;
      case this.router.url === '/contact':
        this.theme = dark;
        break;
      default:
        this.theme = light;
    }
  }

}
