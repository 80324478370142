import { Component, HostListener } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ControlsService } from 'src/app/services/controls.service';

@Component({
  selector: 'app-landing-section',
  templateUrl: './landing-section.component.html',
  styleUrls: ['./landing-section.component.scss']
})
export class LandingSectionComponent {

  opacity = 1;
  lastScrollTop = 0;
  transform = 0;

  constructor(private controlsService: ControlsService) { }

  onScrollToView(className: string) {
    this.controlsService.scrollToView(className);
  }

  @HostListener('window:scroll')
  onScroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (this.opacity > 0 && scrollTop > this.lastScrollTop) {
      this.opacity = this.opacity - 0.015;

      if (this.transform > -200)
        this.transform = this.transform - 1;
    }
    else if (this.opacity < 1) {
      this.opacity = this.opacity + 0.015;

      if (this.transform < 0)
        this.transform = this.transform + 1;
    }

    setTimeout(() => {
      if (scrollTop < 2) {
        this.opacity = 1;
        this.transform = 0;
      }
    }, 100);

    this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
  }


}
