<div class="blog">

    <div class="blog__wrapper">

        <div class="blog__container">

            <div class="blog__posts">
                <app-posts></app-posts>
            </div>

        </div>

    </div>

</div>