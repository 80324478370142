<div class="carousel">

    <div class="carousel__wrapper" #container>

        <div class="carousel__container" [style.height.vh]="height" [style.transform]="'translateX(' + translate + 'px)'">
            <ng-content></ng-content>
        </div>

        <div class="carousel__controls" *ngIf="showControls">
            <button class="carousel__controls-prev" [style.height.vh]="height" (click)="onMove('prev')" *ngIf="currentIndex !== 0">
                <div class="carousel__controls-arrow">
                    <span></span>
                </div>
            </button>
            <button class="carousel__controls-next" [style.height.vh]="height" (click)="onMove('next')" *ngIf="currentIndex !== data.length - 1">
                <div class="carousel__controls-arrow">
                    <span></span>
                </div>
            </button>
        </div>

        <ul class="carousel__points" *ngIf="showPoints && data.length > 1">
            <li [class.active]="currentIndex == i" *ngFor="let item of data; let i = index;" (click)="onSelect(i)"></li>
        </ul>

    </div>

</div>